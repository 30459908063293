'use strict';
(function() {

  class FindingComponent {
    constructor(Auth, usSpinnerService, Image, Finding, $stateParams, $state, $scope, $uibModal,
        Modal, toastr, Upload, Util, Report, ActivityLog, SharedUrl, $sce) {
      this.Finding = Finding;
      this.Modal = Modal;
      this.Upload = Upload;
      this.Image = Image;
      this.getTotalCapacity = Util.getTotalCapacity;
      this.$uibModal = $uibModal;
      this.$stateParams = $stateParams;
      this.usSpinnerService = usSpinnerService;
      this.toastr = toastr;
      this.isLoggedIn = Auth.isLoggedIn;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.getDefectRecommendation = Util.getDefectRecommendation;
      this.getCavityInspectionTypeId = Util.getCavityInspectionTypeId;
      this.Report = Report;

      this.finding = {};
      this.ActivityLog = ActivityLog;
      this.SharedUrl = SharedUrl;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.$sce = $sce;
      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          this.init();
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go('login');
        }
      });
      this.bladePositionMap = {
        'A': 1,
        'B': 2,
        'C': 3
      };
      this.dragOverClass = {accept:'dragover', reject:'dragover-err', pattern:'image/*'};
      this.modelOptionsObj = {debounce:100};
    }

    $onInit() {
    }

    init() {
      this.loading = true;
      this.Finding.get({id: 'get-finding', controller: this.$stateParams.id}).$promise
        .then(response => {
          if (response.data) {
            this.finding = new this.Finding(response.data);
            this.bladeComponents = this.getBladesGroup(this.finding);
            console.log('finding: ', this.finding);
            this.bladeStr = '';
            if(this.finding.blade.make) {
              this.bladeStr+=this.finding.blade.make+' ';
            }
            if(this.finding.blade.model) {
              this.bladeStr+=this.finding.blade.model;
            }
            if(this.finding.blade.name) {
              this.bladeStr+=this.finding.blade.name;
            }

            this.turbineStr = '';
            if(this.finding.turbine.make) {
              this.turbineStr+=this.finding.turbine.make;
            }
            if(this.finding.turbine.model) {
              this.turbineStr+=this.finding.turbine.model;
            }
            if(this.finding.turbine.name) {
              this.turbineStr+=this.finding.turbine.name;
            }

            this.siteOverview = {
              reportNumber: Math.round(Date.now()/1000),
              siteName: this.finding.turbine.site.name,
              siteLocation: `${this.finding.turbine.site.location.addressLine3}, ${this.finding.turbine.site.location.addressLine4}, ${this.finding.turbine.site.location.country}`,
              sitePosition: this.finding.turbine.sitePosition,
              turbineSerialNo: this.finding.turbine.serial,
              turbineBlades: [this.finding.blade],
              turbineMake: this.finding.turbine.make,
              turbineModel: this.finding.turbine.model,
              completionDate: new Date(this.finding.date || this.finding.createdAt),
              reportDate: new Date()
            };
          }
          this.loading = false;
          this.stopSpin('spinner-1');
        })
        .catch(err=> {
          console.log(err);
          this.stopSpin('spinner-1');
          this.loading = false;
          this.finding = null;
        })
    }

    openImageSlideshow(activeIndex) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/blade/findingImageSlideshow/findingImageSlideshow.html',
        controller: 'FindingImageSlideshowCtrl',
        controllerAs: 'findingSlideshow',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Finding Images',
            activeIndex: activeIndex,
            finding: this.finding
          }
        }
      });
      // modalInstance.result.then();
    }

    removeImage(index) {
      this.Modal.confirm.delete(()=>{
        this.Finding.deleteImage({ id: this.finding._id, page: index }).$promise
        .then(response=>{
          this.finding.images.splice(index, 1);
          this.toastr.success('Image removed from finding');
        })
        .catch(err=>{
          console.log('Err', err);
          this.toastr.error('Something went wrong. please try again later.');
        })
      })('image from finding');
    }

    uploadImage(file) {
      var filename = file.name;
      var type = file.type;
      var query = {
          filename: filename,
          type: type
      };
      this.isUploading = true;
      this.Image.signing({}, query).$promise
      .then(result=>{
        this.Upload.upload({
          url: result.url, //s3Url
          transformRequest: function(data, headersGetter) {
            var headers = headersGetter();
            delete headers.Authorization;
            return data;
          },
          fields: result.fields, //credentials
          method: 'POST',
          file: file
        }).progress((evt) => {
          this.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
          // console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total));
        }).success((data, status, headers, config) => {
          this.isUploading = false;
          // file is uploaded successfully
          // console.log('file ' + config.file.name + 'is uploaded successfully. Response: ' + data);
          let finding = angular.copy(this.finding);
          finding.images.push(result.url+'/'+result.fields.key);
          this.Finding.update({ id: this.finding._id}, finding).$promise
          .then((response)=>{
            this.toastr.success('Image added to finding');
            // console.log('updatedFinding', response);
            this.finding = response.data;
          })
          .catch(err=>{
            console.log('err', err);
            if(err.data && err.data.meta && err.data.meta.error_type == 'VersionError') {
              this.toastr.error(err.data.meta.error_message);
            } else {
              this.toastr.error('Image was not added to finding. please try again later');
            }
          });
        }).error((err) => {
          this.isUploading = false;
          console.log('err', err);
          this.toastr.error('Image was not added to finding. please try again later');
        });
      });
    }

    openAddFindingModal() {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/finding/addEditFindingModal/addEditFindingModal.html',
        controller: 'AddEditFindingModalCtrl',
        controllerAs: 'aEFinding',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Edit Finding',
            finding: this.finding
          }
        }
      });
      modalInstance.result.then(()=>{
        this.init();
      });
    }

    getCategoryType(category) {
      let cat = ['No Risk', 'Low', 'Medium', 'Serious', 'Major', 'Critical'];
      return cat[parseInt(category)];
    }

    getClass(finding) {
      return {'resolved': finding.status == 'RESOLVED', 'critical': finding.category == 5, 'major': finding.category == 4, 'serious': finding.category == 3, 'medium': finding.category == 2, 'low': finding.category == 1, 'no-risk': finding.category == 0}
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    getBladePosition(position) {
      return this.bladePositionMap[position]?this.bladePositionMap[position]:position;
    }

    getBladesGroup(finding) {
      let allBlades = [{findings: [finding], blade: finding.blade}];
      return allBlades;
    }

    print() {
      // document.title = `Windmanager - ${this.finding.turbine.site.name} ${this.finding.turbine.serial} - ${moment().format('D MMM YYYY')}.pdf`;
      window.print();
      const activityLogData = {
        site: this.finding.turbine.site._id,
        turbine: this.finding.turbine._id,
        blade: this.finding.blade._id,
        finding: this.finding._id,
        user: this.currentUser._id,
        source: 'web',
        action: 'print',
        type: 'finding',
        sourceScreen: 'finding',
        eventString: `Finding No <a href="/blade/${this.finding.blade._id}/finding/${this.finding._id}">${this.finding.uniqueReferenceNumber}</a> was printed in wind farm <a href="/site/${this.finding.turbine.site._id}">${this.finding.turbine.site.name}</a>, turbine <a href="/turbine/${this.finding.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${this.finding.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`
      }

      this.ActivityLog.save(activityLogData).$promise
        .then(activityLog => {
          console.log(activityLog);
        })
        .catch(err => {
          console.log('Error inserting activityLog', err);
      });
      // document.title = tempTitle;
    }

    createReport() {
      console.log('createReport called');
      this.Modal.confirm.confirmCreateReport(success => {
        console.log('yes');
        const addReportData = {
          _id: this.finding.turbine.site._id,
          site: this.finding.turbine.site,
          turbines: [{'_id': this.finding.turbine._id}],
          blades: [{'_id': this.finding.blade._id}],
          findings: [this.finding._id],
          type: 'finding',
          entityType: 'findings',
          entityId: this.finding._id,
          reportNumber: Date.now(),
          createdBy: this.currentUser._id
        }
        console.log('addReportData:', addReportData);
        this.Report.addReport(addReportData).$promise
        .then(report => {
          this.toastr.success('Report request submitted.');
          const activityLogData = {
            site: this.finding.turbine.site._id,
            turbine: this.finding.turbine._id,
            blade: this.finding.blade._id,
            finding: this.finding._id,
            user: this.currentUser._id,
            source: 'web',
            action: 'create report',
            type: 'finding',
            sourceScreen: 'finding',
            eventString: `Finding Report was created for wind farm <a href="/site/${this.finding.turbine.site._id}">${this.finding.turbine.site.name}</a>, turbine <a href="/turbine/${this.finding.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${this.finding.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`
          }
    
          this.ActivityLog.save(activityLogData).$promise
            .then(activityLog => {
              console.log(activityLog);
            })
            .catch(err => {
              console.log('Error inserting activityLog', err);
          });
        })
        .catch(err => {
          console.log(err);
          this.error = err;
          this.toastr.error('Something went wrong. please try later or contact administrator.');
        });
      },'Create Report', `<p>A report will be emailed to ${this.currentUser.email}. Proceed?</p>`);
    }

    shareUrl() {
      const shareUrlData = {
        url: `blade/${this.finding.blade._id}/finding/${this.finding._id}`,
        level: 'finding',
        organization: this.currentUser.organization._id,
      }
      var url = window.location.href;
      var urlArray = url.split("/");
      var baseUrl = urlArray[0] + "//" + urlArray[2];
      this.SharedUrl.save(shareUrlData).$promise
        .then(sharedUrlResponse => {
          this.urlShared = `${baseUrl}/shared/${sharedUrlResponse.data._id}`;
          this.htmlPopover = this.$sce.trustAsHtml(`${this.urlShared} <i>Users without an account will see a read-only version of this page.</i>`);
          this.showShareUrlPopup = true;
          var dummyElement = document.createElement("textarea");
          document.body.appendChild(dummyElement);
          dummyElement.value = this.urlShared;
          dummyElement.select();
          document.execCommand("copy");
          document.body.removeChild(dummyElement);
          this.toastr.success('Link copied successfully.');
        })
        .catch(err => {
          console.log('Error inserting sharedUrl', err);
      });
    }
  }

  angular.module('windmanagerApp')
    .component('finding', {
      templateUrl: 'app/finding/finding.html',
      controller: FindingComponent,
      controllerAs: 'fc'
    });

})();
